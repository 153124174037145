// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-article-template-article-template-jsx": () => import("./../../../src/components/templates/article-template/article-template.jsx" /* webpackChunkName: "component---src-components-templates-article-template-article-template-jsx" */),
  "component---src-components-templates-famitips-template-famitips-template-jsx": () => import("./../../../src/components/templates/famitips-template/famitips-template.jsx" /* webpackChunkName: "component---src-components-templates-famitips-template-famitips-template-jsx" */),
  "component---src-components-templates-product-product-jsx": () => import("./../../../src/components/templates/product/product.jsx" /* webpackChunkName: "component---src-components-templates-product-product-jsx" */),
  "component---src-components-templates-products-products-jsx": () => import("./../../../src/components/templates/products/products.jsx" /* webpackChunkName: "component---src-components-templates-products-products-jsx" */),
  "component---src-components-templates-qualify-qualify-jsx": () => import("./../../../src/components/templates/qualify/qualify.jsx" /* webpackChunkName: "component---src-components-templates-qualify-qualify-jsx" */),
  "component---src-components-templates-sustainibility-template-sustainibility-template-jsx": () => import("./../../../src/components/templates/sustainibility-template/sustainibility-template.jsx" /* webpackChunkName: "component---src-components-templates-sustainibility-template-sustainibility-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-calculadora-papel-higienico-jsx": () => import("./../../../src/pages/calculadora-papel-higienico.jsx" /* webpackChunkName: "component---src-pages-calculadora-papel-higienico-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-donde-estamos-jsx": () => import("./../../../src/pages/nosotros/donde-estamos.jsx" /* webpackChunkName: "component---src-pages-nosotros-donde-estamos-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */),
  "component---src-pages-nosotros-nuestra-familia-jsx": () => import("./../../../src/pages/nosotros/nuestra-familia.jsx" /* webpackChunkName: "component---src-pages-nosotros-nuestra-familia-jsx" */),
  "component---src-pages-nosotros-somos-familia-jsx": () => import("./../../../src/pages/nosotros/somos-familia.jsx" /* webpackChunkName: "component---src-pages-nosotros-somos-familia-jsx" */),
  "component---src-pages-nosotros-sostenibilidad-jsx": () => import("./../../../src/pages/nosotros/sostenibilidad.jsx" /* webpackChunkName: "component---src-pages-nosotros-sostenibilidad-jsx" */),
  "component---src-pages-terminos-y-condiciones-jsx": () => import("./../../../src/pages/terminos-y-condiciones.jsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-jsx" */)
}

